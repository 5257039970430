import { FormType } from '@/constant/form';

const DICTIONARY = {
  REALITY: 'upperNum',
};
// 温层
const TEMPERATURE_LAYER = {
  NORMAL: '常温',
  COLD: '冷冻',
  REFRIGERATE: '冷藏',
  CONSTANT: '恒温',
};
export { DICTIONARY, TEMPERATURE_LAYER };
export const TABLECOLUMN = [{
  label: '货主货品编码',
  prop: 'shipperGoodsCode',
  width: 120,
}, {
  label: '商品名称',
  prop: 'goodsName',
  width: 80,
}, {
  label: '温层',
  prop: 'temperatureLayer',
  width: 80,
}, {
  label: '规格',
  prop: 'spec',
  width: 80,
}, {
  label: '生产日期',
  prop: 'productDate',
  width: 180,
}, {
  label: '批次号',
  prop: 'batchNo',
  width: 120,
}, {
  label: '货品质量',
  prop: 'goodsQualityTxt',
  width: 80,
}, {
  label: '待上架容器',
  prop: 'containerCode',
  width: 150,
}, {
  label: '待上架数量',
  prop: 'subUpperShelfNumStr',
  width: 160,
}, {
  label: '实际上架数量',
  prop: DICTIONARY.REALITY,
  width: 180,
}, {
  label: '推荐上架库位',
  prop: 'recommendStorehouseCode',
  width: 120,
}, {
  label: '实际上架库位',
  prop: 'storehouseNo',
  width: 120,
  select: true,
}, {
  label: '上架容器',
  prop: 'containerNo',
  width: 200,
  select: true,
}];
export const serchFields = [
  {
    label: '入库单号',
    prop: 'receiptOrderNo',
    component: FormType.INPUT,
  }, {
    label: '待上架容器',
    prop: 'containerCode',
    component: FormType.INPUT,
  },
];
