import { wmsPlusHttp } from '@/utils/http/index';

/**
 * @description:待上架货品明细列表
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/13314
 */
export function paperShelfList(data) {
  return wmsPlusHttp.post('/warehouse_management_system/receipt/paper_shelf/list', data);
}
/**
 * @description:上架确认
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/13258
 */
export function taskCommit(data) {
  return wmsPlusHttp.post('/warehouse_management_system/receipt/paper_shelf/task/commit', data);
}
/**
 * @description: 分页查询
 * @api文档：https://yapi.ops.yunlizhi.cn/project/388/interface/api/11204
 */

export function warehouseContainerPage(params, data) {
  return wmsPlusHttp.post('/warehouse_foundation/container/page', data, {
    params,
  });
}
