<template>
  <nh-detail-page>
    <pl-block>
      <PlForm
        v-model="searchQuery"
        mode="search"
        :fields="serchFields"
        @submit="handleQuery"
        @reset="handleQuery"
      />
    </pl-block>
    <pl-block>
      <div class="flex_column full-content">
        <div class="margin-bottom_normal">
          <h5>待上架货品明细</h5>
        </div>
        <el-table
          v-loading="tableLoading"
          :data="tableData"
          class="table-component"
        >
          <el-table-column type="index" label="序号" />
          <nh-table-column
            v-for="item in TABLECOLUMN"
            :key="item.prop"
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            :show-overflow-tooltip="true"
          >
            <template #default="scope">
              <!-- 如果是实际上架数量 -->
              <div v-if="item.prop === DICTIONARY.REALITY" class="table-input">
                <template v-if="scope.row.conversionRate != 1">
                  <el-input
                    v-model="scope.row.bigUnitNumber"
                    step-strictly
                    :min="0"
                    size="mini"
                    :controls="false"
                    placeholder="请输入"
                    :step="1"
                    @blur="handleBlurNum(scope.row,scope.$index)"
                  />
                  {{ scope.row.bigUnit }}
                </template>
                <el-input
                  v-model="scope.row.smallUnitNumber"
                  size="mini"
                  step-strictly
                  :min="0"
                  :controls="false"
                  placeholder="请输入"
                  :step="0.001"
                  @blur="handleBlurNum(scope.row,scope.$index)"
                />
                {{ scope.row.smallUnit }}
              </div>
              <div v-else-if="item.input">
                <el-input
                  v-model="scope.row[item.prop]"
                  size="mini"
                />
              </div>
              <div v-else-if="item.select">
                <nh-remote-select
                  v-model="scope.row[item.prop]"
                  :loading="loading.onSearchOptions"
                  :remote-method="(keyword) => {
                    onSearchOptions(keyword, item, scope.row)
                  }"
                >
                  <el-option
                    v-for="option in scope.row[`${item.prop}Options`]"
                    :key="option.value"
                    :label="option.label"
                    :value="option.value"
                  />
                </nh-remote-select>
              </div>
              <div v-else>
                {{ scope.row[item.prop] }}
              </div>
            </template>
          </nh-table-column>
        </el-table>
      </div>
    </pl-block>
    <template #footer>
      <div class="footer">
        <nh-button
          type="primary"
          :disabled="tableData.length < 1 || loading.handleSubmit"
          :track="{
            trackName: '入库管理/纸单上架/[上架确认]'
          }"
          @click="handleSubmit"
        >
          上架确认
        </nh-button>
      </div>
    </template>
  </nh-detail-page>
</template>
<script>
import moment from 'moment';
import loadingMixin from '@thales/loading';
import unitNumber from '@/utils/unitNumber';
import { YMDHMS } from '@/constant/timeFormat';
import InWarehouse from '@/constant/pageNames/inWarehouse';
import { storehousePage } from '@/views/base/warehouse-about/warehouse-storehouse/api';
import { warehouseContainerPage } from '@/views/base/warehouse-about/container/api';
import { paperShelfList, taskCommit } from './api';

import {
  DICTIONARY,
  TABLECOLUMN, serchFields,
  TEMPERATURE_LAYER,
} from './fileds';

export default {
  name: InWarehouse.ACCEPTANCE,
  mixins: [loadingMixin],
  data() {
    return {
      DICTIONARY,
      serchFields,
      TABLECOLUMN,
      tableData: [],
      searchQuery: {},
      loading: {
        getTableData: false,
        handleSubmit: false,
        onSearchOptions: false,
      },
    };
  },
  computed: {
    tableLoading() {
      return this.loading.getTableData || this.loading.handleSubmit;
    },
    GOODS_QUALITY() {
      return this.$dictionaryFiledMap.GOODS_QUALITY || [];
    },
  },
  activated() {
    this.getTableData();
  },
  methods: {
    async onSearchOptions(keyword, column, row) {
      if (column.prop === 'storehouseNo') {
        await this.searchStorageLocationCodes(keyword, row);
      } else if (column.prop === 'containerNo') {
        await this.searchContainerNos(keyword, row);
      }
    },
    async searchStorageLocationCodes(keyword, row) {
      const { records } = await storehousePage({ storageLocationCode: keyword });
      const current = row;
      current.storehouseNoOptions = records.map(({ storageLocationCode }) => ({
        value: storageLocationCode,
        label: storageLocationCode,
      }));
    },
    async searchContainerNos(keyword, row) {
      const { records } = await warehouseContainerPage({ containerCode: keyword });
      const current = row;
      current.containerNoOptions = records.map(({ containerCode }) => ({
        value: containerCode,
        label: containerCode,
      }));
    },
    handleQuery() {
      this.getTableData();
    },
    isNumber(obj) {
      return typeof obj === 'number';
    },

    isConversionRate(rate, max, small) {
      if (rate === 1 || rate === '1') {
        return {
          max: 0,
          small: max,
        };
      }
      return {
        max,
        small,
      };
    },
    async getTableData() {
      const resp = await paperShelfList(this.searchQuery);
      const list = {};
      TABLECOLUMN.forEach((item) => {
        list[item.prop] = null;
      });
      this.tableData = resp.map((item) => {
        // 默认上架数量为待上架数量
        const defaultUpperShelfNum = unitNumber.calculate({
          smallUnitNumber: item.subUpperShelfNum || 0,
          conversionRate: item.conversionRate,
        });
        // 如果有一键上架导入的数据， 使用导入字段
        const uploadUpperShelfNum = (this.isNumber(item.importBigUnitCount)
        || this.isNumber(item.importSmallUnitCount)) ? this.isConversionRate(
            item.conversionRate,
            item.importBigUnitCount,
            item.importSmallUnitCount,
          ) : {
            max: defaultUpperShelfNum.bigUnitNumber,
            small: defaultUpperShelfNum.smallUnitNumber,
          };
        return {
          ...list,
          ...item,
          temperatureLayer: TEMPERATURE_LAYER[item.temperatureLayer],
          amount: item.preUpperShelfNum,
          productDate: item.productDate && moment(item.productDate).format(YMDHMS),
          goodsQualityTxt: this.filterLabel(item.goodsQuality, this.GOODS_QUALITY),
          bigUnitNumber: uploadUpperShelfNum.max,
          smallUnitNumber: uploadUpperShelfNum.small,
          storehouseNoOptions: [],
          containerNoOptions: [],
          containerNo: item.importContainerNo || item.containerCode,
          storehouseNo: item.importStorehouseNo || item.recommendStorehouseCode,
        };
      });
    },
    filterLabel(val, arr) {
      const filertArr = arr && arr.find((item) => item.value === val);
      return filertArr && filertArr.label;
    },
    handleBlurNum(row, index) {
      const { bigUnitNumber, smallUnitNumber } = unitNumber.calculate(row);
      const total = unitNumber.getTotal(row);
      const {
        bigUnitNumber: maxBigUnitNumber,
        smallUnitNumber: maxSmallUnitNumber,
      } = unitNumber.calculate({ conversionRate: row.conversionRate, smallUnitNumber: row.amount });
      if (total > row.amount) {
        this.tableData[index].bigUnitNumber = maxBigUnitNumber || null;
        this.tableData[index].smallUnitNumber = maxSmallUnitNumber || null;
        this.tableData[index].allocateAmount = row.amount;
      } else {
        this.tableData[index].bigUnitNumber = bigUnitNumber > 0 ? bigUnitNumber : null;
        this.tableData[index].smallUnitNumber = smallUnitNumber > 0 ? smallUnitNumber : null;
        this.tableData[index].allocateAmount = total;
      }
    },
    async handleSubmit() {
      const data = this.tableData.map((item) => ({
        taskId: item.taskId,
        goodsId: item.goodsId,
        // smallUnitNumber: unitNumber.getTotal(item),
        smallUnitCount: item.smallUnitNumber,
        bigUnitCount: item.bigUnitNumber,
        containerNo: item.containerNo,
        storehouseNo: item.storehouseNo,
      }));
      await taskCommit({ action: 'UPPER', contents: data });
      this.$message({
        type: 'success',
        message: '确认成功!',
      });
      this.getTableData();
    },
  },
};
</script>
<style scoped lang="scss">
.table-input {
  :deep(.el-input) {
    width: 50px;
  }
}

.footer {
  border-top: 1px solid #eee;
  padding: 8px 24px 8px 0;
  background-color: #fff;
  text-align: right;
}

</style>
